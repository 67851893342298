.steps .step {
  display: block;
  width: 100%;
  margin-bottom: 35px;
  text-align: center
}
.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  text-align: center
}
.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 10px;
  margin-top: -3px;
  background-color: #e1e7ec;
  content: '';
  z-index: 1
}
.steps .step .step-icon-wrap::before {
  left: 0
}
.steps .step .step-icon-wrap::after {
  right: 0
}
.steps .step .step-icon {
  display: inline-block;
  position: relative;
  margin-top: 27px;
  width: 30px;
  height: 30px;
  border: 1px solid #e1e7ec;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #374250;
  font-size: 11px;
  line-height: 81px;
  z-index: 5
}
.steps .step .step-title {
  margin-top: 0px;
  margin-bottom: 0;
  color: #606975;
  font-size: 14px;
  font-weight: 500
}
.steps .step:first-child .step-icon-wrap::before {
  display: none
}
.steps .step:last-child .step-icon-wrap::after {
  display: none
}
.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
  background-color: #525E75
}
.steps .step.completed .step-icon {
  border-color: #525E75;
  background-color: #525E75;
  color: #fff
}
@media (max-width: 576px) {
  .flex-sm-nowrap .step .step-icon-wrap::before,
  .flex-sm-nowrap .step .step-icon-wrap::after {
      display: none
  }
}
@media (max-width: 768px) {
  .flex-md-nowrap .step .step-icon-wrap::before,
  .flex-md-nowrap .step .step-icon-wrap::after {
      display: none
  }
}
@media (max-width: 991px) {
  .flex-lg-nowrap .step .step-icon-wrap::before,
  .flex-lg-nowrap .step .step-icon-wrap::after {
      display: none
  }
}
@media (max-width: 1200px) {
  .flex-xl-nowrap .step .step-icon-wrap::before,
  .flex-xl-nowrap .step .step-icon-wrap::after {
      display: none
  }
}
/* .bg-faded, .bg-secondary {
  background-color: #f5f5f5 !important;
} */